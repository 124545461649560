import { Component, OnInit } from '@angular/core';
declare var getCookie: any;
declare var delCookie: any;
@Component({
  selector: 'ngx-business-user-panel',
  templateUrl: './business-user-panel.component.html',
  styleUrls: ['./business-user-panel.component.scss']
})
export class BusinessUserPanelComponent implements OnInit {

  curBusinessName:any=getCookie("username");
  curBusinessType:any=getCookie("usertype");;
  
  constructor() { }

  ngOnInit() {
    this.isUserLogin();
  }

  //检测用户登录状态
  isUserLogin(){
    if(getCookie("Token") == null ){
      location.href="/#/userlogin";
    }
  }
  //退出登录
  userLogout(){
    delCookie('username');
    delCookie('usertype');
    delCookie('Token');
    location.reload();
  }
}
