import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService, AppGlobal } from '../providers/app.service';
import { AppConfig, AppStaticConfig } from '../providers/app.config';
declare var Swiper: any;
@Component({
  selector: 'app-default',
  templateUrl: './default.html',
  styleUrls: ['./default.scss']
})
export class DefaultComponent implements OnInit {
  images = []
  imgindex: any = {}//图文滑动的图片组
  isShow: any = [];
  imgcount: number;//记录下一个栏目有多少条数据
  funCode: string = '0x10100200100106';//信息类型编号
  errMsgCode: string = '0001';//错误类型编号 
  dtDataList = []//专委会新闻数据
  zxDataList = []//行业资讯数据
  hyDataList = []//专委会黄页数据
  hylistDataList = []//会员数据
  PagesInfoViewModel: any = { //分页model
    PageIndex: 1,
    PageSize: 4
  }
  constructor(
    private router: Router,
    private appService: AppService,
    private routeInfo: ActivatedRoute,
    private appConfig: AppConfig,
  ) { }

  ngOnInit() {

    document.body.style.background = "#fff"
    this.getUserIP()
    sessionStorage.clear()
    //this.images.push({ source: './assets/images/banner1920-380.jpg',url:'#'});
    this.getListInfoData('1', '5')//专委会新闻
    this.getListInfoData_hy()//专委会动态
    this.getListInfoData('3', '4')//会员风采
    this.getListInfoData('4', '2')//会员简介
    this.getListInfoData('5', '8')//头部广告
    setTimeout(() => {
    this.initSwiper();
     }, 20);
   }
  initSwiper() {
    var swiper = new Swiper('.swiper-container', {
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      observer:true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents:true,//修改swiper的父元素时，自动初始化swiper
    });
  }
  //鼠标放在文章标题时，触发左边图片切换
  newschange(item: any) {
    for (let i = 0; i < this.imgcount; i++) {

      this.isShow[i] = 'hide';
    }
    this.isShow[item] = 'show';
  }
  //获取图文列表(categoryId:分类ID，itemCount：要取出的条数)
  getListInfoData(categoryId: any, itemCount: any) {
    let pagesInfoViewModel = { //分页model
      PageIndex: 1,
      PageSize: itemCount
    }
    let reCeiveData = {
      funCode: this.funCode,
      errMsgCode: this.errMsgCode,
      categoryId: categoryId,
      imgTypeId: 4,
      pages: pagesInfoViewModel
    };
    this.appService.httpPost(AppGlobal.API.getManageInfoFront, reCeiveData, rs => {
      if (rs.code == "9999") {
        if (rs.objectData.retData.length > 0) {
          let tempArray = [];
          for (let i = 0; i < rs.objectData.retData.length; i++) {
            let pageDataModel = {
              title: '',
              date: '',
              image: '',
              cid: '',
              infoid: '',
              abstract: '',
              name: '',//会员名
              zhiwu: '',//职务
              vip_AccountName: '',
              infoBrowseNum: '',
              infoMark: ''
            }
            pageDataModel.title = rs.objectData.retData[i].info_Title;
            pageDataModel.abstract = rs.objectData.retData[i].info_Guide;
            pageDataModel.image = AppGlobal.domain + rs.objectData.retData[i].imagesViewModel.imgPath + rs.objectData.retData[i].imagesViewModel.imgName;
            pageDataModel.date = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate);
            pageDataModel.cid = rs.objectData.retData[i].info_CategoryId;
            pageDataModel.infoid = rs.objectData.retData[i].info_Id
            pageDataModel.name = (rs.objectData.retData[i].info_Title).trim().split(/\s+/)[0];//会员名
            pageDataModel.zhiwu = (rs.objectData.retData[i].info_Title).trim().split(/\s+/)[1];//职务
            pageDataModel.vip_AccountName = rs.objectData.retData[i].vip_AccountName;
            pageDataModel.infoBrowseNum = rs.objectData.retData[i].infoBrowseNum;
            pageDataModel.infoMark = rs.objectData.retData[i].info_Mark;
            tempArray.push(pageDataModel);

            if (categoryId == 1) {
              this.imgcount = rs.objectData.retData.length;
              this.imgindex[i] = AppGlobal.domain + rs.objectData.retData[i].imagesViewModel.imgPath + rs.objectData.retData[i].imagesViewModel.imgName;
            }
          }
          // if(categoryId ==1){
          //   this.imgindex=AppGlobal.domain + rs.objectData.retData[0].imagesViewModel.imgPath + rs.objectData.retData[0].imagesViewModel.imgName;
          // }

          if (categoryId == '1') {
            this.dtDataList = tempArray;//专委会新闻数据赋值
            this.isShow[0] = 'show';//第一张图调用样式表show来显示，其他隐藏
          }
          else if (categoryId == '2') {
            this.zxDataList = tempArray;//行业动态数据赋值
          } else if (categoryId == '3') {
            this.hyDataList = tempArray;//会员风采数据赋值
          }
          else if (categoryId == '4') {
            this.hylistDataList = tempArray;//会员风采数据赋值
          } else if (categoryId == '5') {
            let reg = RegExp(/http/);
            this.images = []
            for (let i = 0; i < tempArray.length; i++) {
              let tempImgModel = {
                source: '',
                url: ''
              }
              if (tempArray[i].infoMark.match(reg) && tempArray[i].infoMark != '') {
                // 包含http字段且不为空
                tempImgModel.source = tempArray[i].image;
                tempImgModel.url = tempArray[i].infoMark;
                this.images.push(tempImgModel)
              } else {
                tempImgModel.source = tempArray[i].image;
                tempImgModel.url = tempArray[i].infoMark;
                this.images.push(tempImgModel)
              }
            }
          }
        }
      }
    });
  }
  jumpLink(item: any) {
    this.router.navigate(['/otherdetail'], { queryParams: { 'cid': item.cid, 'info': item.infoid, 'tit': item.title } });
  }
  //获取客户端IP
  getUserIP() {
    let userip = localStorage.getItem('userip')
    console.log('userip', userip)
    if (userip == null)
      this.appService.getUserIP(rs => {
        localStorage.setItem('userip', rs.query)
      })
  }
   //获取专委会会议
   getListInfoData_hy() {
     let reCeiveData = {
       funCode: '0x1010020010010',
       errMsgCode: '0001',
       categoryId: '6',
       imgTypeId: 4,
       markTag: '70',
       pages: this.PagesInfoViewModel
     };
     let tempArray = [];
     this.appService.httpPost(AppGlobal.API.getManageInfoFront, reCeiveData, rs => {
       // //console.log(rs)
       if (rs.code == "9999") {
         if (rs.objectData.retData.length > 0) {
           //let tempArray = [];
           for (let i = 0; i < rs.objectData.retData.length; i++) {
             let pageDataModel = {
               title: '',
               dateY: '',
               dateM: '',
               dateD: '',
               image: '',
               abstract: '',
               cid: '',
               infoid: '',
               vip_AccountName: '',
               infoBrowseNum: ''
             }
             pageDataModel.title = rs.objectData.retData[i].info_Title;
             pageDataModel.abstract = rs.objectData.retData[i].info_Guide;
             pageDataModel.image = AppGlobal.domain + rs.objectData.retData[i].imagesViewModel.imgPath + rs.objectData.retData[i].imagesViewModel.imgName;
             pageDataModel.dateY = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate);
             pageDataModel.dateM = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate, 'month');
             pageDataModel.dateD = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate, 'date');
             pageDataModel.cid = rs.objectData.retData[i].info_CategoryId;
             pageDataModel.infoid = rs.objectData.retData[i].info_Id;
             pageDataModel.vip_AccountName = rs.objectData.retData[i].vip_AccountName;
             pageDataModel.infoBrowseNum = rs.objectData.retData[i].infoBrowseNum;
             tempArray.push(pageDataModel);
             console.log('5');
           }
           this.zxDataList = tempArray;
           //console.log(tempArrayzy);
         }
       }
     });
   }
}
