import { Component, OnInit } from '@angular/core';


//遮罩层loading
@Component({
    selector: 'block-component',
    styleUrls: ['./block.component.scss'],
    template: `
        <p-blockUI [blocked]="blocked">
            <div class="flip-txt-loading">
                <span>L</span><span>o</span><span>a</span><span>d</span><span>i</span><span>n</span><span>g</span>
            </div>
        </p-blockUI>`
})
export class BlockLevelComponent implements OnInit {
    blocked: boolean = true;
    ngOnInit() {
    }
}