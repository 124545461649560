import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService, AppGlobal } from '../../providers/app.service';
import { AppConfig, AppStaticConfig } from '../../providers/app.config';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'ngx-mg201908',
  templateUrl: './mg201908.component.html',
  styleUrls: ['./mg201908.component.scss'],
  providers: [MessageService],
})
export class Mg201908Component implements OnInit {

  Period: any = '';//会议期数
  UserName: any = '';//联系人
  UnitName: any = '';//单位
  Phone: any = '';//电话
  unitType: any = '生产企业';//企业类型
  isMember: any = 1;//是否会员
  bmDate: any = '';//报名日期
  details: any = '';//备注

  constructor(
    private router: Router,
    private appService: AppService,
    private routeInfo: ActivatedRoute,
    private appConfig: AppConfig,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    let that = this;
    that.getPeriod();//必须最先执行，获取最新有效的会议期数赋值给that.Period
  }


  //企业类型选择
  unitTypeClick(e) {
    let that = this;
    switch (e) {
      case 'scqy':
        that.unitType = '生产企业';
        break;
      case 'syqy':
        that.unitType = '商业企业';
        break;
      case 'boqy':
        that.unitType = '生产和商业企业';
        break;
    }
  }

  //是否专委会会员
  isMemberClick(e) {
    let that = this;
    switch (e) {
      case 'y':
        that.isMember = 1;
        break;
      case 'n':
        that.isMember = 0;
        break;
    }
  }

  //获取会议期数
  getPeriod() {
    let that = this;
    let postData = {
      errMsgCode: '0001',
      funCode: '0x10100200100105',
      typeId: '1',
    }
    that.appService.httpPost(AppGlobal.API.getMeetingPeriodsNoToken, postData, rs => {
      if (rs.code == "9999") {
        if (rs.objectData.length > 0) {
          that.Period = rs.objectData[0].infoCategoryId;
        }
      }
    });
  }


  // Generate four random hex digits. 
  S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  // Generate a pseudo-GUID by concatenating random hexadecimal. 
  guid() {
    let that = this;
    return (that.S4() + that.S4() + "-" + that.S4() + "-" + that.S4() + "-" + that.S4() + "-" + that.S4() + that.S4() + that.S4());
  };

  subInfo() {
    let that = this;
    if (that.UnitName != '' && that.UserName != '' && that.Phone != '') {
      let postData = {
        errMsgCode: '0001',
        funCode: '0x10100200100106',
        infoViewModel: {
          InfoMeid: '00000000-0000-0000-0000-000000000000', /// 参会报名ID
          InfoMename: that.UnitName, /// 参会报名企业名称
          InfoMecontact: that.UserName, /// 参会报名联系人
          InfoMetel: that.Phone, /// 参会报名联系人电话
          InfoMemark: that.details, /// 参会报名备注
          InfoMecreateTime: '2019-02-14', /// 参会报名时间
          InfoMeisMember: that.isMember == 1 ? true : false, /// 参会报名是否会员
          InfoMegrade: '0', /// 参会报名会员级别(默认0)
          InfoMecustom1: that.guid(), /// 参会报名登录用户ID,这里写死了，每次都随机生成一个，不然提示用户已报名
          InfoMecustom2: that.Period, /// 参会报名期数分类ID
          InfoMecustom3: that.unitType, /// 预留1,存期数  存企业性质：0生产企业  1销售企业 2全有
          InfoMecustom4: '', /// 预留2,存主题
        },
      }
      that.appService.httpPost(AppGlobal.API.getMeetingSubmitmeinfo, postData, rs => {
        //console.log(rs)
        if (rs.code == "9999") {
          that.clearData();
          alert('您的报名信息已成功提交! \r\n请保持您的联系方式畅通，会务组会与您联络。');
        }
        else {
          alert(rs.errorDetail);
        }
      });
    }
    else {
      alert('带红星的项目必须输入！')
    }

  }

  clearData(){
    let that=this;
    that.UserName = '';//联系人
    that.UnitName= '';//单位
    that.Phone = '';//电话
    that.unitType = '生产企业';//企业类型
    that.isMember = 1;//是否会员
    that.bmDate = '';//报名日期
    that.details = '';//备注
  }


}
