import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  actClass: any = 'products';//按钮标签的文字，默认'选择图片'
  constructor() { }

  ngOnInit() {
    document.body.style.background="" 
  }

}
