import { Component, OnInit } from '@angular/core';
import { AppService, AppGlobal } from '../providers/app.service';
import { AppConfig, AppStaticConfig } from '../providers/app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-articledetail',
  templateUrl: './articledetail.html',
  styleUrls: ['./articledetail.scss']
})
export class ArticleDetailComponent implements OnInit {
  sub: any;//获取浏览器参数
  categoryId: any//浏览器参数
  infoId: any//浏览器参数
  funCode: string = '0x10100200100107';//信息类型编号
  errMsgCode: string = '0001';//错误类型编号 
  pageTitle:any;//文章标题
  pageContent:any;//文章内容
  pageAuthor:any;//文章作者
  pageOrigin:any;//文章来源
  actClass: any;//导航条选中栏目
  articlelist = [];//页面右侧数据
  leftListTitle:any;//页面右侧列表标题
  leftListUrl:any;//页面右侧列表跳转目标
  Info_BrowseSubmit: any = {
    InfoBrowseId: 'ca3b89d1-9ff5-4998-b6d9-972d7a7e80e9',// 信息浏览ID
    InfoBrowseIp: '',// 信息浏览IP
    InfoBrowseCreateDate: '2018-12-22',// 信息浏览日期
    InfoId: '',// 信息ID
  }
  constructor(
    private routeInfo: ActivatedRoute,
    private appService: AppService,
    private sanitized: DomSanitizer
  ) { }

  ngOnInit() {
    document.body.style.background=""
    this.sub = this.routeInfo.queryParams.subscribe(params => {
      this.categoryId = params['cid'];
      this.infoId = params['info'];
      this.pageTitle = params['tit'];
      this.getInfoData(this.categoryId,this.infoId)
      this.getInfoList()
      if(this.categoryId=='1'){
        this.actClass='dt'
        this.leftListTitle='最新专委会新闻';
        this.leftListUrl='articlelistdt'
      }else if(this.categoryId=='2'){
        this.actClass='zx'
        this.leftListTitle='最新行业资讯';
        this.leftListUrl='articlelist'
      }
      else if(this.categoryId=='4'){
        this.actClass=''
        this.leftListTitle='会员风采';
        this.leftListUrl='articlelist'
      }
    })
    this.postViewNum();
  }
   //获取文章详细内容
   getInfoData(categoryId:any,infoId:any){
    let reCeiveData = {
      funCode: this.funCode,
      errMsgCode: this.errMsgCode,
      infoId: this.infoId
    };
    this.appService.httpPost(AppGlobal.API.getManageDetailFront,reCeiveData,rs => {
        if (rs.code == "9999") {
          this.pageContent=this.sanitized.bypassSecurityTrustHtml(rs.objectData.infoContent)
          this.pageAuthor=rs.objectData.infoAuthor
          this.pageOrigin=rs.objectData.infoOrigin
        }
      }
    )
  }
  //获取文章列表
  getInfoList(){
    //分页model
    let PagesInfoViewModel: any = { 
      PageIndex: 1,
      PageSize: 10
    }
    let reCeiveData = {
      funCode: '0x10100200100106',
      errMsgCode: this.errMsgCode,
      categoryId: this.categoryId,
      imgTypeId: 4,
      pages: PagesInfoViewModel
    };
    this.appService.httpPost(AppGlobal.API.getManageInfoFront, reCeiveData, rs => {
      if (rs.code == "9999") {
        if (rs.objectData.retData.length > 0) {
          let tempArray = [];
          for (let i = 0; i < rs.objectData.retData.length; i++) {
            let pageDataModel = {
              title: '',
              date: '',
              cid: '',
              infoid: ''
            }
            pageDataModel.title = rs.objectData.retData[i].info_Title;
            pageDataModel.date = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate);
            pageDataModel.cid = rs.objectData.retData[i].info_CategoryId;
            pageDataModel.infoid = rs.objectData.retData[i].info_Id
            tempArray.push(pageDataModel);
          }
          this.articlelist = tempArray;
        }
      }
    })
  }
  postViewNum() {
    this.Info_BrowseSubmit.InfoBrowseIp = localStorage.getItem('userip')
    this.Info_BrowseSubmit.InfoId = this.infoId
    let reCeiveData = {
      funCode:this.funCode,
      infoBrowse:this.Info_BrowseSubmit,
      errMsgCode :this.errMsgCode
    }
    this.appService.httpPost(AppGlobal.API.postInfoBrowse, reCeiveData, rs => {
      // //console.log(rs)
    })
  }
}
