import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService, AppGlobal } from '../providers/app.service';
import { AppConfig, AppStaticConfig } from '../providers/app.config';

@Component({
  selector: 'app-imagelist',
  templateUrl: './imagelist.html',
  styleUrls: ['./imagelist.scss']
})
export class ImageListComponent implements OnInit {
  sub: any;//获取浏览器参数
  actClass: any = 'yellow';;//导航条选中栏目，默认是行业动态
  imagelist = [];//页面数据
  totalRecords: any = 0;//总条数
  PagesInfoViewModel: any = { //分页model
    PageIndex: 1,
    PageSize: 100
  }
  funCode: string = '0x10100200100106';//信息类型编号
  errMsgCode: string = '0001';//错误类型编号 
  categoryId: string = '3';//上级分类ID
  constructor(
    private router: Router,
    private appService: AppService,
    private routeInfo: ActivatedRoute,
    private appConfig: AppConfig,
  ) { }

  ngOnInit() {
    document.body.style.background=""
    this.getListInfoData();
  }
  //获取文章列表
  getListInfoData() {
    let reCeiveData = {
      funCode: this.funCode,
      errMsgCode: this.errMsgCode,
      categoryId: this.categoryId,
      imgTypeId: 4,
      pages: this.PagesInfoViewModel
    };
    this.appService.httpPost(AppGlobal.API.getManageInfoFront, reCeiveData, rs => {
      if (rs.code == "9999") {
        if (rs.objectData.retData.length > 0) {
          this.totalRecords = rs.objectData.itemCount;
          let tempArray = [];
          for (let i = 0; i < rs.objectData.retData.length; i++) {
            let pageDataModel = {
              title: '',
              dateY: '',
              dateM: '',
              dateD: '',
              image: '',
              abstract: '',
              cid: '',
              infoid: ''
            }
            pageDataModel.title = rs.objectData.retData[i].info_Title;
            pageDataModel.abstract = rs.objectData.retData[i].info_Guide;
            pageDataModel.image = AppGlobal.domain + rs.objectData.retData[i].imagesViewModel.imgPath + rs.objectData.retData[i].imagesViewModel.imgName;
            pageDataModel.dateY = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate, 'year');
            pageDataModel.dateM = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate, 'month');
            pageDataModel.dateD = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate, 'date');
            pageDataModel.cid = rs.objectData.retData[i].info_CategoryId;
            pageDataModel.infoid = rs.objectData.retData[i].info_Id
            tempArray.push(pageDataModel);
          }
          this.imagelist = tempArray;
        }
      }
    }
    );
  }
  jumpLink(item: any) {
    console.log(item)
    this.router.navigate(['/otherdetail'], {queryParams: {'cid': item.cid,'info':item.infoid,'tit':item.title }});
  }
}
