import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService, AppGlobal } from '../../providers/app.service';
import { AppConfig, AppStaticConfig } from '../../providers/app.config';
declare var $: any;
declare var getCookie: any;
declare var delCookie: any;
declare var DataTable: any;
@Component({
  selector: 'ngx-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {

  sub: any;//获取浏览器参数
  actClass: any = 'business';;//导航条选中栏目
  rz: boolean = true;
  jl: boolean = false;//////二级导航条是否选中
  js: boolean = false;
  tt: boolean = false;//////二级导航条是否选中
  articlelist = [];//页面数据
  totalRecords: any = 0;//总条数
  PagesInfoViewModel: any = { //分页model
    PageIndex: 1,
    PageSize: 20
  }
  funCode: string = '0x10100200100106';//信息类型编号
  errMsgCode: string = '0001';//错误类型编号 
  categoryId: string = '7';//上级分类ID
  markid: string = '50';//二级分类ID，默认显示道地商
  constructor(
    private router: Router,
    private appService: AppService,
    private routeInfo: ActivatedRoute,
    private appConfig: AppConfig,
  ) { }

  ngOnInit() {
    document.body.style.background = ""
    /** 分类
        this.secDataTable = [{ label: '请选择信息分类', value: null },
       { label: '专委会文件', value: '10' },
       { label: '销售业务公示', value: '20' },
       { label: '生产业务公示', value: '30' }, 
       { label: '内部集采价格', value: '40' }, 
       { label: '道地商认证', value: '50' }, 
       { label: '奖励表扬企业', value: '60' },
        { label: '警示企业', value: '70' }, 
        { label: '末尾淘汰企业', value: '80' }, 
        { label: '销售额排比', value: '90' }];
  */
    //获取浏览器地址栏参数
    this.sub = this.routeInfo.queryParams.subscribe(params => {
      this.markid = params['markid'];
      this.getListInfoData(this.markid);//初始显示生产企业
    })

  }
  //懒加载页面滚动
  loadData(event) {
    // this.getListInfoData()
  }

  //改变选中按钮样式
  naviClick(e) {
    //首先全部清空
    this.rz = false;
    this.jl = false;
    this.js = false;
    this.tt = false;
    //在对应设置谁被选中，选中的添加样式样式
    switch(e){
      case 'rz':
          this.rz = true;
      break;
      case 'jl':
          this.jl = true;
      break;
      case 'js':
          this.js = true;
      break;
      case 'tt':
          this.tt = true;
      break;
      default:
          this.rz = true;
    }
  }
  //获取文章列表
  getListInfoData(buType: any) {

    let reCeiveData = {
      funCode: this.funCode,
      errMsgCode: this.errMsgCode,
      categoryId: this.categoryId,
      imgTypeId: 4,
      markTag: buType,//加入专委会小程序后动用了信息类别的未用字段来保存子类别
      pages: this.PagesInfoViewModel

    };
    this.appService.httpPost(AppGlobal.API.getMeetingInfo, reCeiveData, rs => {
      //console.log(rs)
      if (rs.code == "9999") {
        if (rs.objectData.retData.length > 0) {
          this.totalRecords = rs.objectData.itemCount;
          let tempArray = [];
          for (let i = 0; i < rs.objectData.retData.length; i++) {
            let pageDataModel = {
              title: '',
              dateY: '',
              dateM: '',
              dateD: '',
              image: '',
              abstract: '',
              cid: '',
              infoid: '',
              vip_AccountName: '',
              infoBrowseNum: ''
            }
            pageDataModel.title = rs.objectData.retData[i].info_Title;
            pageDataModel.abstract = rs.objectData.retData[i].info_Guide;
            pageDataModel.image = AppGlobal.domain + rs.objectData.retData[i].imagesViewModel.imgPath + rs.objectData.retData[i].imagesViewModel.imgName;
            pageDataModel.dateY = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate);
            pageDataModel.dateM = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate, 'month');
            pageDataModel.dateD = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate, 'date');
            pageDataModel.cid = rs.objectData.retData[i].info_CategoryId;
            pageDataModel.infoid = rs.objectData.retData[i].info_Id;
            pageDataModel.vip_AccountName = rs.objectData.retData[i].vip_AccountName;
            pageDataModel.infoBrowseNum = rs.objectData.retData[i].infoBrowseNum;
            tempArray.push(pageDataModel);
          }
          this.articlelist = tempArray;
        }
      }
    }
    );
  }

}
