import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  actClass: any = 'contactus';//按钮标签的文字，默认'选择图片'
  constructor() { }

  ngOnInit() {
    document.body.style.background="" 
  }

}
