import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService, AppGlobal } from '../providers/app.service';
import { AppConfig, AppStaticConfig } from '../providers/app.config';
declare var $: any;
declare var jigsaw: any;
declare var getCookie: any;
declare var delCookie: any;
declare var setCookie: any;
@Component({
  selector: 'ngx-userlogin',
  templateUrl: './userlogin.component.html',
  styleUrls: ['./userlogin.component.scss'],

})
export class UserloginComponent implements OnInit {
  username: any = "";
  userpass: any = "";
  AccountViewModel: any = {
    UserName: '',
    Password: '',
    ValidCode: '',
  }

  constructor(
    private router: Router,
    private appService: AppService,
    private routeInfo: ActivatedRoute,
    private appConfig: AppConfig,
  ) { }

  ngOnInit() {
    this.hahah();
  }

  hahah() {
    let that = this;

    jigsaw.init({
      el: document.getElementById('captcha'),
      //执行登录数据提交，注意写法，.bind(this)是在callback事件中调用其他方法的写法
      // onSuccess:validLogin.bind(this),
      onSuccess: function () {
        //发送数据model
        that.AccountViewModel = {
          UserName: that.username,
          Password: that.userpass,
          ValidCode: '',
        };
        that.appService.httpPostSuyuan(AppGlobal.APISuyuan.UserLogin, that.AccountViewModel, rs => {
          console.log(rs)
          if(rs.Result.Result){
            setCookie('username', rs.Result.UserInfo.OrgName, 'h24');
            setCookie('usertype', rs.Result.UserInfo.MemberType==1?'生产企业':'销售企业', 'h24');
            setCookie('Token', rs.Token, 'h24');
            location.href = '/#/AllianceDoc?markid=10';
          }
          else{
            alert(rs.Result.ErrorMessage);
          }

        });
        document.getElementById('msg').innerHTML = '验证成功！';

        
        // setCookie('username', this.username, 'h24');
        // setCookie('usertype', '1', 'h24');
        // setCookie('userlevel', '1', 'h24');
        // location.href = '/#/AllianceDoc?markid=10';
      },
      onFail: cleanMsg,
      onRefresh: cleanMsg
    })

    function cleanMsg() {
      document.getElementById('msg').innerHTML = ''
    }




  }
}
