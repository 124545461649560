import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FilesUploadComponent } from './fileupload.component';
import { FileUploadModule } from 'primeng/fileupload';


@NgModule({
  declarations: [
    FilesUploadComponent
  ],
  imports: [
    CommonModule,
    FileUploadModule
  ],
  exports: [
    FilesUploadComponent
  ]
})
export class FilesUploadModule {
}