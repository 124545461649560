import { Component, OnInit } from '@angular/core';
import { AppService, AppGlobal } from '../providers/app.service';
import { AppConfig, AppStaticConfig } from '../providers/app.config';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-otherdetail',
  templateUrl: './otherdetail.html',
  styleUrls: ['./otherdetail.scss']
})
export class OtherDetailComponent implements OnInit {
  sub: any;//获取浏览器参数
  categoryId: any//浏览器参数
  infoId: any//浏览器参数
  funCode: string = '0x10100200100107';//信息类型编号
  errMsgCode: string = '0001';//错误类型编号 
  pageTitle:any;//文章标题
  pageContent:any;//文章内容
  pageAuthor:any;//文章作者
  pageOrigin:any;//文章来源
  actClass: any;//导航条选中栏目
  articlelist = [];//页面右侧数据
  leftListTitle:any;//页面右侧列表标题
  leftListUrl:any;//页面右侧列表跳转目标
  constructor(
    private routeInfo: ActivatedRoute,
    private appService: AppService
  ) { }

  ngOnInit() {
    document.body.style.background=""
    this.sub = this.routeInfo.queryParams.subscribe(params => {
      this.categoryId = params['cid'];
      this.infoId = params['info'];
      this.pageTitle = params['tit'];
      this.getInfoData(this.categoryId,this.infoId)
      if(this.categoryId=='3'){
        this.actClass='yellow'
      }
    })
  }
   //获取文章详细内容
   getInfoData(categoryId:any,infoId:any){
    let reCeiveData = {
      funCode: this.funCode,
      errMsgCode: this.errMsgCode,
      infoId: this.infoId
    };
    this.appService.httpPost(AppGlobal.API.getManageDetailFront,reCeiveData,rs => {
        if (rs.code == "9999") {
          this.pageContent=rs.objectData.infoContent
          this.pageAuthor=rs.objectData.infoAuthor
          this.pageOrigin=rs.objectData.infoOrigin
        }
      }
    )
  }
}
