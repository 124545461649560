import { Component, OnInit, Input } from '@angular/core';
import { PlatformLocation} from '@angular/common';
@Component({
  selector: 'app-com-pagehead',
  templateUrl: './pagehead.component.html',
  styleUrls: ['./pagehead.component.scss']
})
export class PageHeadComponent implements OnInit {
  @Input() actClass: any = 'index';//按钮标签的文字，默认'选择图片'
  indexpage: boolean = false;
  dtpage: boolean = false;
  zxpage: boolean = false;
  aboutpage: boolean = false;
  yellowpage: boolean = false;
  contactpage: boolean = false;
  productpage: boolean = false;
  businesspage: boolean = false;
  collectweb: boolean = false;
  isShowMenu:any = {
    display:'none'
  }
  i:any=0;//计数器
  constructor(private location: PlatformLocation) { }

  ngOnInit() {
    this.selectActionClass();
    
  }
  selectActionClass() {
    this.indexpage = false;
    this.dtpage = false;
    this.zxpage = false;
    this.aboutpage = false;
    this.yellowpage = false;
    this.contactpage = false;
    this.businesspage = false;
    if (this.actClass == 'index') {
      this.indexpage = true;
    } else if (this.actClass == 'dt') {
      this.dtpage = true;
    } else if (this.actClass == 'zx') {
      this.zxpage = true;
    } else if (this.actClass == 'aboutus') {
      this.aboutpage = true;
    } else if (this.actClass == 'yellow') {
      this.yellowpage = true;
    } else if (this.actClass == 'contactus') {
      this.contactpage = true;
    }else if(this.actClass == 'products'){
      this.productpage = true;
    }
    else if(this.actClass == 'business'){
      this.businesspage = true;
    }
    //setInterval("document.getElementById('nowDate1').innerHTML=new Date().toLocaleString()+' 星期'+'日一二三四五六'.charAt(new Date().getDay());", 1000);
  }
  
  showMenu(){
    
    if(this.i == 0){
      this.isShowMenu.display = 'block';
      this.i =1;
    }
    else{
      this.isShowMenu.display = 'none';
      this.i =0;
    }
    
  }
  AddFavorite() {
    console.log(this.location)
    // for (const i in this.location) {
    //   if (i === 'location') {
    //     window.sidebar
    //     window.addEventListener()
    //     console.log(this.location[i].origin);
    //     break;
    //   }
    // }
    alert('收藏网站失败，请使用Ctrl+D进行添加!');
  }
  Addover(){
    this.collectweb = true;
  }
  Addleave(){
    this.collectweb = false;
  }
}
