import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppService, AppGlobal } from '../providers/app.service';
import { AppConfig, AppStaticConfig } from '../providers/app.config';
import { MessageService } from 'primeng/api';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.html',
  styleUrls: ['./login.scss'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {
  loginform: FormGroup; //初始化表单
  submitted: boolean; //是否显示遮罩层
  funCode: string = 'OX01';//功能编号
  errMsgCode: string = 'OX01E01';//错误编号
  // dragContainer: any;
  // dragBg: any;
  // dragText: any;
  // dragHandler: any;
  // isVertifySucc: boolean = false;
  //maxHandlerOffset: any;
  Vip_MAccountLoginInfoViewModel: any = {
    VipAccountName: '',
    VipAccountPwd: ''
  }
  constructor(private router: Router,
    private appService: AppService,
    private appConfig: AppConfig,
    private messageService: MessageService,
    private fb: FormBuilder) { }
  //数据初始化
  ngOnInit(): void {
    document.body.style.background=""
    this.submitted = false;
    sessionStorage.clear()
    this.loginform = this.fb.group({
      'loginname': new FormControl('', Validators.required),
      'loginpwd': new FormControl('', Validators.required),
    });
  }
  //用户登录
  onSubmit() {
    this.submitted = true;
    this.Vip_MAccountLoginInfoViewModel.VipAccountName = this.loginform.value.loginname
    this.Vip_MAccountLoginInfoViewModel.VipAccountPwd = this.loginform.value.loginpwd
    let receiveData = {
      funCode: this.funCode,
      maccountlogin: this.Vip_MAccountLoginInfoViewModel,
      errMsgCode: this.errMsgCode
    }
    this.appService.httpPost(AppGlobal.API.loginMaccountInfo, receiveData, rs => {
      this.submitted = false;
      if (rs.code == '9999') {
        //console.log(rs)
        sessionStorage.setItem('token', rs.objectData)
        this.router.navigateByUrl('/pages/dashboard');
      } else {
        this.messageService.add({ severity: 'error', summary: '信息提示', detail: rs.errorMessage });
      }
    });
  }
  //滑块初始化
  // initialSliding() {
  //   this.dragContainer = document.getElementById("dragContainer");
  //   this.dragBg = document.getElementById("dragBg");
  //   this.dragText = document.getElementById("dragText");
  //   this.dragHandler = document.getElementById("dragHandler");
  //   this.maxHandlerOffset = this.dragContainer.clientWidth - this.dragHandler.clientWidth;
  //   this.dragHandler.addEventListener("mousedown", this.onDragHandlerMouseDown);
  //   this.dragHandler.addEventListener("touchstart", this.onDragHandlerMouseDown);
  // }
  // onDragHandlerMouseDown(event) {
  //  
  //   document.addEventListener("mousemove", this.onDragHandlerMouseMove);
  //   document.addEventListener("mouseup", this.onDragHandlerMouseUp);
  //   document.addEventListener("touchmove", this.onDragHandlerMouseMove);
  //   document.addEventListener("touchend", this.onDragHandlerMouseUp);
  // }
  // onDragHandlerMouseMove(event) {
  //   /*
  //   html元素不存在width属性,只有clientWidth
  //   offsetX是相对当前元素的,clientX和pageX是相对其父元素的
  //   touches：表示当前跟踪的触摸操作的touch对象的数组。
  //   targetTouches：特定于事件目标的Touch对象的数组。
  // changedTouches：表示自上次触摸以来发生了什么改变的Touch对象的数组。
  //   */
  //   let left = (event.clientX || event.changedTouches[0].clientX) - this.dragHandler.clientWidth / 2;
  //   if (left < 0) {
  //     left = 0;
  //   } else if (left > this.maxHandlerOffset) {
  //     left = this.maxHandlerOffset;
  //     this.verifySucc();
  //   }
  //   this.dragHandler.style.left = left + "px";
  //   this.dragBg.style.width = this.dragHandler.style.left;
  // }
  // onDragHandlerMouseUp(event) {
  //   document.removeEventListener("mousemove", this.onDragHandlerMouseMove);
  //   document.removeEventListener("mouseup", this.onDragHandlerMouseUp);
  //   document.removeEventListener("touchmove", this.onDragHandlerMouseMove);
  //   document.removeEventListener("touchend", this.onDragHandlerMouseUp);
  //   this.dragHandler.style.left = 0;
  //   this.dragBg.style.width = 0;
  // }

  // //验证成功
  // verifySucc() {
  //   this.isVertifySucc = true;
  //   this.dragText.textContent = "验证通过";
  //   this.dragText.style.color = "white";
  //   this.dragHandler.setAttribute("class", "dragHandlerOkBg");
  //   this.dragHandler.removeEventListener("mousedown", this.onDragHandlerMouseDown);
  //   document.removeEventListener("mousemove", this.onDragHandlerMouseMove);
  //   document.removeEventListener("mouseup", this.onDragHandlerMouseUp);
  //   this.dragHandler.removeEventListener("touchstart", this.onDragHandlerMouseDown);
  //   document.removeEventListener("touchmove", this.onDragHandlerMouseMove);
  //   document.removeEventListener("touchend", this.onDragHandlerMouseUp);
  // };
}
