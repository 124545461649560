import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService, AppGlobal } from '../providers/app.service';
import { AppConfig, AppStaticConfig } from '../providers/app.config';
@Component({
  selector: 'ngx-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {
  actClass: any='aboutus';;//导航条选中栏目
  images: any[]
  imgindex: string = '1'//图文滑动的图片
  funCode: string = '0x10100200100106';//信息类型编号
  errMsgCode: string = '0001';//错误类型编号 
  dtDataList=[]//专委会新闻数据
  zxDataList=[]//行业资讯数据
  hyDataList=[]//专委会黄页数据
  hylistDataList=[]//会员数据
  constructor(
    private router: Router,
    private appService: AppService,
    private routeInfo: ActivatedRoute,
    private appConfig: AppConfig,
  ) { }


  ngOnInit() {
    document.body.style.background="url(/assets/images/bg_01.jpg) repeat left top"
    sessionStorage.clear()
    this.getListInfoData('4','2')//会员简介
  }
//获取图文列表(categoryId:分类ID，itemCount：要取出的条数)
getListInfoData(categoryId:any,itemCount:any) {
  let pagesInfoViewModel = { //分页model
    PageIndex: 1,
    PageSize: itemCount
  }
  let reCeiveData = {
    funCode: this.funCode,
    errMsgCode: this.errMsgCode,
    categoryId: categoryId,
    imgTypeId: 4,
    pages: pagesInfoViewModel
  };
  this.appService.httpPost(AppGlobal.API.getManageInfoFront, reCeiveData, rs => {
    if (rs.code == "9999") {
      // //console.log(rs)
      if (rs.objectData.retData.length > 0) {
        let tempArray = [];
        for (let i = 0; i < rs.objectData.retData.length; i++) {
          let pageDataModel = {
            title: '',
            date: '',
            image: '',
            cid: '',
            infoid: '',
            abstract:'',
            name:'',//会员名
            zhiwu:''//职务
          }
          pageDataModel.title = rs.objectData.retData[i].info_Title;
          pageDataModel.abstract = rs.objectData.retData[i].info_Guide;
          pageDataModel.image = AppGlobal.domain + rs.objectData.retData[i].imagesViewModel.imgPath + rs.objectData.retData[i].imagesViewModel.imgName;
          pageDataModel.date = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate);
          pageDataModel.cid = rs.objectData.retData[i].info_CategoryId;
          pageDataModel.infoid = rs.objectData.retData[i].info_Id
          pageDataModel.name = (rs.objectData.retData[i].info_Title).trim().split(/\s+/)[0];//会员名
          pageDataModel.zhiwu = (rs.objectData.retData[i].info_Title).trim().split(/\s+/)[1];//职务
          tempArray.push(pageDataModel);
          if(i==0){
            this.imgindex=AppGlobal.domain + rs.objectData.retData[i].imagesViewModel.imgPath + rs.objectData.retData[i].imagesViewModel.imgName;
          }
        }
          this.hylistDataList = tempArray;//会员风采数据赋值
        
      }
    }
  });
}
jumpLink(item: any) {
  
  this.router.navigate(['/otherdetail'], {queryParams: {'cid': item.cid,'info':item.infoid,'tit':item.title }});
}
}
