import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
// import {
//   NbAuthComponent,
//   NbLoginComponent,
//   NbLogoutComponent,
//   NbRegisterComponent,
//   NbRequestPasswordComponent,
//   NbResetPasswordComponent,
// } from '@nebular/auth';
import { DefaultComponent } from './default/default'
import { LoginComponent } from './login/login'
import { ImageListComponent } from './imagelist/imagelist'
import { ArticleListComponent } from './articlelist/articlelist'
import { ArticleListDtComponent } from './articlelistdt/articlelistdt'
import { ArticleDetailComponent } from './articledetail/articledetail'
import { OtherDetailComponent } from './otherdetail/otherdetail';
import { AboutusComponent } from './aboutus/aboutus.component';
import { B2bComponent } from './b2b/b2b.component';
import { ContactusComponent } from './contactus/contactus.component';
import { ProductsComponent } from './products/products.component';
import { SeComponent } from './se/se.component';
import { SebComponent } from './seb/seb.component';
import { UserloginComponent } from './userlogin/userlogin.component';
import { BusinessComponent } from './business/business.component';
import { SalesParallelismComponent } from './business/sales-parallelism/sales-parallelism.component';
import { AllianceDocComponent } from './business/alliance-doc/alliance-doc.component';
import { AllianceMeetingComponent } from './business/alliance-meeting/alliance-meeting.component';
import { AuthenticationComponent } from './business/authentication/authentication.component';
import { CautionComponent } from './business/caution/caution.component';
import { EliminateComponent } from './business/eliminate/eliminate.component';
import { InternalCollectionPriceComponent } from './business/internal-collection-price/internal-collection-price.component';
import { RewardComponent } from './business/reward/reward.component';
import { Mg201908Component } from './MeetingPage/mg201908/mg201908.component';
const routes: Routes = [
  { path: '', redirectTo: 'default',  pathMatch: 'full'},  //项目启动的第一个路由为default
  {
    path: 'login',
    component: LoginComponent
  },{
    path: 'default',
    component: DefaultComponent
  }, {
    path: 'imagelist',
    component: ImageListComponent
  }, {
    path: 'articlelist',
    component: ArticleListComponent
  }, {
    path: 'articledetail',
    component: ArticleDetailComponent
  },{
    path: 'otherdetail',
    component: OtherDetailComponent
  },{
    path: 'articlelistdt',
    component: ArticleListDtComponent
  },{
    path: 'aboutus',
    component: AboutusComponent
  },{
    path: 'b2b',
    component: B2bComponent
  },{
    path: 'contactus',
    component: ContactusComponent
  },{
    path: 'products',
    component: ProductsComponent
  },
  {
    path: 'se',
    component: SeComponent
  },
  {
    path: 'seb',
    component: SebComponent
  },
  {
    path: 'userlogin',
    component: UserloginComponent
  },
  {
    path: 'business',
    component:BusinessComponent
  },
  {
    path: 'AllianceDoc',
    component:AllianceDocComponent
  },
  {
    path: 'AllianceMeeting',
    component:AllianceMeetingComponent
  },
  {
    path: 'Authentication',
    component:AuthenticationComponent
  },
  {
    path: 'Caution',
    component:CautionComponent
  },
  {
    path: 'Eliminate',
    component:EliminateComponent
  },
  {
    path: 'InternalCollectionPrice',
    component:InternalCollectionPriceComponent
  },
  {
    path: 'Reward',
    component:RewardComponent
  },
  {
    path: 'SalesParallelism',
    component:SalesParallelismComponent
  },
  {
    path: 'meet201908',
    component:Mg201908Component
  },
  // {
  //   path: 'auth',
  //   component: NbAuthComponent,
  //   children: [
  //     {
  //       path: '',
  //       component: NbLoginComponent,
  //     },
  //     {
  //       path: 'login',
  //       component: NbLoginComponent,
  //     },
  //     {
  //       path: 'register',
  //       component: NbRegisterComponent,
  //     },
  //     {
  //       path: 'logout',
  //       component: NbLogoutComponent,
  //     },
  //     {
  //       path: 'request-password',
  //       component: NbRequestPasswordComponent,
  //     },
  //     {
  //       path: 'reset-password',
  //       component: NbResetPasswordComponent,
  //     },
  //   ],
  // },
  //{ path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: 'pages', loadChildren: 'app/pages/pages.module#PagesModule' },
  { path: '**', redirectTo: 'default' },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
