import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-business-navi',
  templateUrl: './business-navi.component.html',
  styleUrls: ['./business-navi.component.scss']
})
export class BusinessNaviComponent implements OnInit {
  
  sub: any;//获取浏览器参数
  markid: string = '';//二级分类ID浏览器地址栏传参


  AllianceDoc: boolean = false;
  AllianceMeeting: boolean = false;
  business: boolean = false;
  SalesParallelism: boolean = false;
  InternalCollectionPrice: boolean = false;
  Authentication: boolean = false;
  constructor(
    private routeInfo: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.naviClick();//每次页面加载的时候调用
  }

  naviClick() {
    //获取浏览器地址栏参数
    this.sub = this.routeInfo.queryParams.subscribe(params => {
      this.markid = params['markid'];
    })
    //首先全部清空
    this.AllianceDoc = false;
    this.AllianceMeeting = false;
    this.business = false;
    this.SalesParallelism = false;
    this.InternalCollectionPrice = false;
    this.Authentication = false;
//在对应设置谁被选中，选中的添加样式样式
    switch(this.markid){
      case '10':
          this.AllianceDoc = true;
      break;
      case '100':
          this.AllianceMeeting = true;
      break;
      case '30':
          this.business = true;
      break;
      case '90':
          this.SalesParallelism = true;
      break;
      case '40':
          this.InternalCollectionPrice = true;
      break;
      case '50':
          this.Authentication = true;
      break;
      default:
          this.AllianceDoc = true;

    }
  }
}
