import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AppService, AppGlobal } from '../../providers/app.service';

@Component({
  selector: 'app-com-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss']
})
export class FilesUploadComponent implements OnInit {
  //监听组件触发数据
  @Output() event = new EventEmitter;//实例化EventEmitter，赋值给event，event为组件触发事件
  @Input() upfileType: any;//上传组件文件选取的类型，由父组件传递过来.例如：”image/*“表示图片，具体可参考input file的accept类型
  @Input() fileType: any = 'image';//上传组件文件的类型，目前只有图片和视频，输入内容为，image或者video，默认为image
  @Input() upLoadUrl: any;//上传组件文件上传地址
  @Input() maxFileSize: any='2100000';//文件最大大小（字节）,默认大小2M
  @Input() chooseLabel: any='选择图片';//按钮标签的文字，默认'选择图片'
  fileInfo = {//组件数据的信息
    //fileID: '',
    //filePath: '',
    //fileCategoryId:'',
    //fileCreateDate:'',
    //fileName:'',
    //fileObjectId:'',
    //fileOrderNum:'',
    //fileTitle:'',
    //fileTypeId:'',
    //filevipAccountId:'',
  };
  // ImgId : string ='00000000-0000-0000-0000-000000000000';
  // ImgTypeId : number = 1;//图片类型ID  如：头像，商品
  // ImgCategoryId : number = 1;//图片分类ID
  // ImgName : any;//上传图片的名称
  // ImgPath :any;//路径
  // ImgTitle : any;//标题
  // ImgDec : any;//描述
  // ImgOrderNum : number=1;//排序
  // ImgObjectId : string;//业务关联ID
  funCode: string = 'OX01';//项目功能编号
  errMsgCode: string = 'OX01E02C';//错误信息编号
  constructor(private appService: AppService) { }
  ngOnInit() {
  }
  //自动触发上传事件
  myUploader(event) {
    if (this.upLoadUrl == null || this.upLoadUrl == undefined || this.upLoadUrl == '') {
      console.log('上传地址不能为空')
    } else {
      let dataFormFile = event.files[0]
      let formData: FormData = new FormData();
      // formData.append('ImgId ', this.ImgId)
      // formData.append('ImgTypeId ', this.ImgTypeId.toString())
      // formData.append('ImgCategoryId ', this.ImgCategoryId.toString())
      // formData.append('ImgName ', this.ImgName)
      // formData.append('ImgPath ', this.ImgPath)
      // formData.append('ImgTitle ', this.ImgTitle)
      // formData.append('ImgDec ', this.ImgDec)
      // formData.append('ImgOrderNum ', this.ImgOrderNum.toString())
      formData.append('receiveData', dataFormFile)
      formData.append('funCode', this.funCode)
      formData.append('errMsgCode', this.errMsgCode)
      this.appService.httpPost_token_file(this.upLoadUrl, formData, rs => {
        if (rs.code == '9999') {
          // if(this.fileType=='image'){
          //   // this.fileInfo.fileID = rs.objectData.imgId
          //   // this.fileInfo.fileName = rs.objectData.imgName
          //   // this.fileInfo.filePath = rs.objectData.imgPath + rs.objectData.imgName
          //   //this.fileInfo.fileCategoryId = rs.objectData.imgCategoryId
          //   //this.fileInfo.fileCreateDate = rs.objectData.imgCreateDate
          //   //this.fileInfo.fileObjectId = rs.objectData.imgObjectId
          //   //this.fileInfo.fileOrderNum = rs.objectData.imgOrderNum
          //   //this.fileInfo.fileTitle = rs.objectData.imgTitle
          //   // this.fileInfo.fileTypeId = rs.objectData.imgTypeId
          //   //this.fileInfo.filevipAccountId = rs.objectData.vipAccountId
          // }else{
          //   this.fileInfo.fileID = rs.objectData.videoId
          //   this.fileInfo.filePath = rs.objectData.videoPath + rs.objectData.videoName
          // }
          this.event.emit(rs.objectData);
          //this.event.emit(this.fileInfo);
        }
      });
    }

  }
}
