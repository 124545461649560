import { ToasterService, ToasterConfig, Toast, BodyOutputType } from 'angular2-toaster';
import { Injectable } from '@angular/core';
@Injectable()
export class AppStaticConfig {
    //正则手机号
    public static verifyMobile(mobile: string) {
        let verifyCode = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        return verifyCode.test(mobile);
    }
    //正则座机号
    public static verifyPhone(phone: string) {
        let verifyCode = /^(0[0-9]{2,3}\-)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$|(^11[3|4|5|7|8]\d{9}$)/;
        return verifyCode.test(phone);
    }
    //正则邮编
    public static verifyPostCode(postcode: string) {
        let verifyCode = /^[1-9][0-9]{5}$/;
        return verifyCode.test(postcode);
    }
    //正则身份证号
    public static verifyIdentityCardID(identityCardID: string) {
        let verifyCode = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        return verifyCode.test(identityCardID);
    }
    //正则社会统一信用代码
    public static verifyEtaxNumber(etaxNumber: string) {
        let verifyCode = /^[0-9A-Z]{18}/;
        return verifyCode.test(etaxNumber);
    }

    //验证密码强度
    public static verifyPassword(password: string) {
        let verifyCode = /^(?=.*\d)(?=.*[a-z]).{8,16}$/;
        return verifyCode.test(password);
    }
    //创建新的guid
    public static newGuid() {
        let guid = "";
        for (let i = 1; i <= 32; i++) {
            var n = Math.floor(Math.random() * 16.0).toString(16);
            guid += n;
            if ((i == 8) || (i == 12) || (i == 16) || (i == 20))
                guid += "-";
        }
        return guid;
    }
    //隐藏手机号
    public static hideMobile(mobile: string) {
        let part1 = "";
        let part2 = "";
        part1 = mobile.substr(0, 3);
        part2 = mobile.substr(7, 4);
        return `${part1}****${part2}`;
    }
    //输入内容格式化
    public static clearHTML(a) {
        let aNew = "" + a;
        return aNew.replace(/</g, "").replace(/>/g, "").replace(/&/g, "").replace(/script/g, '').replace(/div/g, "").replace(/src/g, "");
    }
    /*
    **日期格式化,date需要处理的数据，
    type处理的类型（分为year，只返回年，month只返回年.date只返回日，默认返回年月日）
    */
    public static DateFormat(date: any, type: string = 'all') {
        date = new Date(date);  
        let returnData = ''
        let dataY = date.getFullYear();
        let dataM = date.getMonth() + 1;
        let dataD = date.getDate();
        if (type == 'year') {
            returnData = dataY.toString()
        }else if(type == 'month'){
            returnData = dataM.toString()
        }else if(type == 'date'){
            returnData = dataD.toString()
        }else{
            returnData= dataY.toString()+'.'+ dataM.toString()+'.'+dataD.toString()
        }
        return returnData
    }

}
@Injectable()
export class AppConfig {
    constructor(private toasterService: ToasterService) { }
    configToaster: ToasterConfig;
    //弹出框
    toasterView(type: string, title: string, body: string) {
        this.configToaster = new ToasterConfig({
            positionClass: 'toast-center',
            timeout: 5000,
            newestOnTop: true,
            tapToDismiss: true,
            preventDuplicates: false,
            animation: 'fade',
            limit: 5,
        });
        const toast: Toast = {
            type: type,
            title: title,
            body: body,
            timeout: 5000,
            showCloseButton: true,
            bodyOutputType: BodyOutputType.TrustedHtml,
        };
        this.toasterService.popAsync(toast);
    }
}