import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlockLevelComponent } from './block.component';
import { BlockUIModule } from "primeng/primeng"


@NgModule({
    imports: [
        CommonModule,
        BlockUIModule
    ],
    declarations: [
        BlockLevelComponent
    ],
    exports: [
        BlockLevelComponent
    ]
})
export class BlockLevelModule {
}