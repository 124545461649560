import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService, AppGlobal } from '../../providers/app.service';
import { AppConfig, AppStaticConfig } from '../../providers/app.config';
@Component({
  selector: 'ngx-alliance-meeting',
  templateUrl: './alliance-meeting.component.html',
  styleUrls: ['./alliance-meeting.component.scss']
})
export class AllianceMeetingComponent implements OnInit {

  sub: any;//获取浏览器参数
  actClass: any='business';;//导航条选中栏目
  articlelist = [];//页面数据
  totalRecords: any=0;//总条数
  PagesInfoViewModel: any = { //分页model
    PageIndex: 1,
    PageSize: 20
  }
  funCode: string = '0x10100200100106';//信息类型编号
  errMsgCode: string = '0001';//错误类型编号 
  categoryId:string='6';//上级分类ID
  constructor(
    private router: Router,
    private appService: AppService,
    private routeInfo: ActivatedRoute,
    private appConfig: AppConfig,
  ) { }

  ngOnInit() {
    document.body.style.background=""
    this.getListInfoData();
  }
  //懒加载页面滚动
  loadData(event) {
    this.getListInfoData()
  }
   //获取文章列表
  getListInfoData(){
  
    let reCeiveData = {
      funCode: this.funCode,
      errMsgCode: this.errMsgCode,
      categoryId: this.categoryId,
      imgTypeId: 4,
      markTag:"70",//加入专委会小程序后动用了信息类别的未用字段来保存子类别
      pages: this.PagesInfoViewModel
      
    };
    this.appService.httpPost(AppGlobal.API.getMeetingInfo,reCeiveData,rs => {
      //console.log(rs)
        if (rs.code == "9999") {
          if (rs.objectData.retData.length > 0) {
            this.totalRecords=rs.objectData.itemCount;
              let tempArray = [];
              for (let i = 0; i < rs.objectData.retData.length; i++) {
                let pageDataModel = {
                  title: '',
                  dateY: '',
                  dateM: '',
                  dateD: '',
                  image: '',
                  abstract:'',
                  cid:'',
                  infoid:'',
                  vip_AccountName:'',
                  infoBrowseNum:''
                }
                pageDataModel.title = rs.objectData.retData[i].info_Title;
                pageDataModel.abstract = rs.objectData.retData[i].info_Guide;
                pageDataModel.image = AppGlobal.domain + rs.objectData.retData[i].imagesViewModel.imgPath + rs.objectData.retData[i].imagesViewModel.imgName;
                pageDataModel.dateY = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate);
                pageDataModel.dateM = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate,'month');
                pageDataModel.dateD = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate,'date');
                pageDataModel.cid =rs.objectData.retData[i].info_CategoryId;
                pageDataModel.infoid =rs.objectData.retData[i].info_Id;
                pageDataModel.vip_AccountName =rs.objectData.retData[i].vip_AccountName;
                pageDataModel.infoBrowseNum =rs.objectData.retData[i].infoBrowseNum;
                tempArray.push(pageDataModel);
              }
              this.articlelist = tempArray;
          }
        }
      }
    );
  }

}
