import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';

import 'rxjs/add/operator/toPromise';
//业务平台数据配置
@Injectable()

export class AppGlobal {
    //接口域名地址
    static domain = 'https://xx.gshsyy.net'
    static domainimage = 'https://xx.gshsyy.net'
    static domain_suyuan = {
        domains: 'https://api.gshsyy.com',
        domainimages: 'https://api.gshsyy.com',
        domainWechat: '125.74.8.24'
    }
    //网站接口地址
    static API: any = {
        postImg: '/api/filesOperation/upload/postimg',//上传图片
        postVideo: '/api/filesOperation/upload/postvde',//上传视频
        postEditorImg: '/api/filesOperation/uploadbyeditortemp/postimg',//上传编辑器图片
        postEditorVideo: '/api/filesOperation/uploadbyeditortemp/postvde',//上传编辑器视频
        loginMaccountInfo: '/api/vip_account/maccount/businesslogin',//账户登录 
        getProductsCategory: '/api/ec_proucts/procategory/info',//获取商品分类信息 
        addProductsCategory: '/api/ec_proucts/procategory/submit',//添加商品分类信息
        editProductsCategory: '/api/ec_proucts/procategory/edit',//编辑商品分类信息
        deleteProductsCategory: '/api/ec_proucts/procategory/delete',//删除商品分类信息
        addProducts: '/api/ec_proucts/proinfo/submit',//添加商品信息
        editProducts: '/api/ec_proucts/proinfo/editinfo',//修改商品信息
        getProductByCategoryid: '/api/ec_proucts/proinfo/bycategoryid',//获取商品信息_按商品分类查询
        getProductByCode: '/api/ec_proucts/proinfo/bycode',//获取商品信息_获取商品信息_按商品编号查询
        getProductByName: '/api/ec_proucts/proinfo/byname',//获取商品信息_按商品名称查询
        getProductById: '/api/ec_proucts/proinfo/byid',//获取商品信息_按商品ID查询
        delProductById: '/api/ec_proucts/proinfo/delete',//删除商品BYID


        /*网站内容维护 */
        ////////INFO信息维护
        getManageInfoByCategoryId: '/api/info/info/listinfo',//获取信息列表
        addManage: '/api/info/info/submitinfo',//添加
        editManage: '/api/info/info/editinfo',//修改
        delManage: '/api/info/info/deleteinfo',//删除
        getManageInfoFront: '/api/info/infoweb/listinfo',//获取前台信息列表
        getManageDetailFront: '/api/info/infoweb/contentinfo',//获取前台信息内容
        ///////info类型
        getManageType: '/api/info/infocategory/getinfo',//获取网站信息分类
        //////删除临时图片
        delTempIMG: '/api/filesoperation/deletetemp/postimg',
        /////信息内容
        getInfoContentByInfoId: '/api/info/info/contentinfo',//获取后台信息内容
        postInfoBrowse: '/api/info/infobrowse/submitinfo',//添加信息浏览
        /////参会信息
        getMeetingPeriods: '/api/info/infocategory/getmeinfo',//获取后台参会信息分类，//获取期数
        addMeetingPeriods: '/api/info/infocategory/addinfo',//后台增加期数
        editMeetingPeriods: '/api/info/infocategory/editinfo',//后台修改期数
        delMeetingPeriods: '/api/info/infocategory/deleteinfo',//后台删除期数
        getMeetingListByPeriods: '/api/info/infomark/listinfo',//后台_自定义备注获取列表
        getMeetingUserList: '/api/info/infome/listinfo',/// 获取报名信息列表_后端

        getMeetingInfo: '/api/info/infomarkweb/listinfo',//获取往期会议前台信息

        
        getMeetingPeriodsNoToken:'/api/info/infowebcategory/getmeinfo',//获取期数无需token，报名用
        getMeetingSubmitmeinfo: '/api/info/infomeweb/submitmeinfo',///// 提交报名信息列表_前端
    };

    //溯源接口地址
    
    static APISuyuan: any = {
        UserLogin: '/api/Account/PostLogin',//专委会溯源微信小程序
        
        
    };

    static systemPhone: any = {
        servicePhone: '0932-6680568', //客服电话
    }
}
@Injectable()
export class AppService {
    constructor(
        public http: Http,
    ) {
    }
    // 对参数进行编码
    encode(params) {
        var str = '';
        if (params) {
            for (var key in params) {
                if (params.hasOwnProperty(key)) {
                    var value = params[key];
                    str += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
                }
            }
            str = '?' + str.substring(0, str.length - 1);
        }
        return str;
    }
    // httpGet_token(url, token, params, callback, loader: boolean = false) {
    //     if (token == null) {
    //         token = '123'
    //     }
    //     let loading = this.loadingCtrl.create({});
    //     let headers = new Headers();
    //     headers.append('Content-Type', 'application/json');
    //     headers.append('Authorization', token);
    //     if (loader) {
    //         loading.present();
    //     }
    //     this.http.get(AppGlobal.domain + url + this.encode(params), new RequestOptions({ headers: headers }))
    //         .toPromise()
    //         .then(res => {
    //             if (loader) {
    //                 loading.dismiss();
    //             }
    //             var d = res.json();
    //             callback(d == null ? "[]" : d);
    //         })
    //         .catch(error => {
    //             if (loader) {
    //                 loading.dismiss();
    //             }
    //             callback(error)
    //             this.handleError(error);
    //         });
    // }

    // httpPostlogin(url, params, callback, loader: boolean = false) {
    //     let headers = new Headers();
    //     headers.append('Content-Type', 'application/json');
    //     let loading = this.loadingCtrl.create();
    //     if (loader) {
    //         loading.present();
    //     }
    //     this.http.post(AppGlobal.domain + url, params, new RequestOptions({ headers: headers }))
    //         .toPromise()
    //         .then(res => {              
    //             if (loader) {
    //                 loading.dismiss();
    //             }
    //             var d = res.json();
    //             callback(d == null ? "[]" : d);
    //         }).catch(error => {
    //             if (loader) {
    //                 loading.dismiss();
    //             }
    //             this.handleError(error);
    //         });
    // }
    httpPost_token(url, params, callback) {
        let token = sessionStorage.getItem('token')
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('authorization', token);
        this.http.post(AppGlobal.domain + url, params, new RequestOptions({ headers: headers }))
            .toPromise()
            .then(res => {
                var d = res.json();
                callback(d == null ? "[]" : d);
            }).catch(error => {
                callback(error);
                this.handleError(error);
            });
    }
    //不需要token
    httpPost(url, params, callback) {
        let headers = new Headers();
        this.http.post(AppGlobal.domain + url, params, new RequestOptions({ headers: headers }))
            .toPromise()
            .then(res => {
                var d = res.json();
                callback(d == null ? "[]" : d);
            }).catch(error => {
                callback(error);
                this.handleError(error);
            });
    }
    //post溯源domain的
    public httpPostSuyuan(url, params, callback) {
        let headers = new Headers();
        headers.append('Accept', '*/*');
        headers.append('Content-Type', 'application/json');
        
        
        this.http.post(AppGlobal.domain_suyuan.domains + url, params, new RequestOptions({ headers: headers }))
            .toPromise()
            .then(res => {
                var d = res.json();
                callback(d == null ? "[]" : d);
            }).catch(error => {
                callback(error);
                this.handleError(error);
            });
    }
    //获取客户端IP
    getUserIP(callback) {
        this.http.get('http://ip-api.com/json')
            .toPromise()
            .then(res => {
                var d = res.json();
                callback(d == null ? "[]" : d);
            }).catch(error => {
                callback(error);
                this.handleError(error);
            });
    }
    //带图片上传
    httpPost_token_file(url, params, callback) {
        let token = sessionStorage.getItem('token')
        let headers = new Headers();
        headers.append('authorization', token);
        this.http.post(AppGlobal.domain + url, params, new RequestOptions({ headers: headers }))
            .toPromise()
            .then(res => {
                var d = res.json();
                callback(d == null ? "[]" : d);
            }).catch(error => {
                callback(error);
                this.handleError(error);
            });
    }
    private handleError(error: Response | any) {
        let msg = '';
        if (error.status == 400) {
            msg = '请求无效(code：400)';
            console.log('请检查参数类型是否匹配');
        }
        if (error.status == 404) {
            msg = '无法连接到网络，请检查您的手机状态';
            console.error(msg + '，请检查路径是否正确');
        }
        if (error.status == 500) {
            msg = '服务器发生错误(code：500)';
            console.error(msg + '，请检查路径是否正确');
        }
        if (msg != '') {
        }
    }
    setItem(key: string, obj: any) {
        try {
            var json = JSON.stringify(obj);
            window.localStorage[key] = json;
        }
        catch (e) {
            console.error("window.localStorage error:" + e);
        }
    }
    getItem(key: string, callback) {
        try {
            var json = window.localStorage[key];
            var obj = JSON.parse(json);
            callback(obj);
        }
        catch (e) {
            console.error("window.localStorage error:" + e);
        }
    }
    // 本地json文件请求
    getRegionContact() {
        return this.http.get("assets/json/region.json")
    }
    // 本地json文件请求
    geteExpressContact() {
        return this.http.get("assets/json/express.json")
    }
    // 本地图片文件请求
    geteImgContact() {
        return this.http.get("assets/imgs/userImg.jpg");
    }
}