import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService, AppGlobal } from '../providers/app.service';
import { AppConfig, AppStaticConfig } from '../providers/app.config';

@Component({
  selector: 'app-articlelist',
  templateUrl: './articlelist.html',
  styleUrls: ['./articlelist.scss']
})
export class ArticleListComponent implements OnInit {
  sub: any;//获取浏览器参数
  actClass: any = 'zx';;//导航条选中栏目
  articlelist = [];//页面数据
  articlelisthy = [];//页面数据
  totalRecords: any = 0;//总条数
  PagesInfoViewModel: any = { //分页model
    PageIndex: 1,
    PageSize: 20
  }
  funCode: string = '0x10100200100106';//信息类型编号
  errMsgCode: string = '0001';//错误类型编号 
  categoryId: string = '2';//上级分类ID
  constructor(
    private router: Router,
    private appService: AppService,
    private routeInfo: ActivatedRoute,
    private appConfig: AppConfig,
  ) { }

  ngOnInit() {
    document.body.style.background = ""
    this.getListInfoData_zy();
    //this.getListInfoData_hy();
  }
  //懒加载页面滚动
  loadData(event) {
    this.getListInfoData_zy();
    //this.getListInfoData_hy();
  }

  //获取动态资讯
   getListInfoData_zy(){
    let reCeiveData = {
      funCode: this.funCode,
      errMsgCode: this.errMsgCode,
      categoryId: this.categoryId,
      imgTypeId: 4,
      pages: this.PagesInfoViewModel
    };
    let tempArray = [];
    this.appService.httpPost(AppGlobal.API.getManageInfoFront, reCeiveData, rs => {
      // //console.log(rs)
      if (rs.code == "9999") {
        if (rs.objectData.retData.length > 0) {
          this.totalRecords = rs.objectData.itemCount;
          //let tempArray = [];
          for (let i = 0; i < rs.objectData.retData.length; i++) {
            let pageDataModel = {
              title: '',
              dateY: '',
              dateM: '',
              dateD: '',
              image: '',
              abstract: '',
              cid: '',
              infoid: '',
              vip_AccountName: '',
              infoBrowseNum: ''
            }
            pageDataModel.title = rs.objectData.retData[i].info_Title;
            pageDataModel.abstract = rs.objectData.retData[i].info_Guide;
            pageDataModel.image = AppGlobal.domain + rs.objectData.retData[i].imagesViewModel.imgPath + rs.objectData.retData[i].imagesViewModel.imgName;
            pageDataModel.dateY = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate);
            pageDataModel.dateM = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate, 'month');
            pageDataModel.dateD = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate, 'date');
            pageDataModel.cid = rs.objectData.retData[i].info_CategoryId;
            pageDataModel.infoid = rs.objectData.retData[i].info_Id;
            pageDataModel.vip_AccountName = rs.objectData.retData[i].vip_AccountName;
            pageDataModel.infoBrowseNum = rs.objectData.retData[i].infoBrowseNum;
            tempArray.push(pageDataModel);
            console.log('4');
          }
          //this.articlelist = tempArray;
          this.getListInfoData_hy();
        }
      }
    });
  }
  //获取专委会会议
  getListInfoData_hy() {
   //getListInfoData_hy(tempArrayzy:any) {
    let reCeiveData = {
      funCode: '0x1010020010010',
      errMsgCode: '0001',
      categoryId: '6',
      imgTypeId: 4,
      markTag: '70',
      pages: this.PagesInfoViewModel
    };
    let tempArray = [];
    this.appService.httpPost(AppGlobal.API.getManageInfoFront, reCeiveData, rs => {
      // //console.log(rs)
      if (rs.code == "9999") {
        if (rs.objectData.retData.length > 0) {
          this.totalRecords = rs.objectData.itemCount;
          //let tempArray = [];
          for (let i = 0; i < rs.objectData.retData.length; i++) {
            let pageDataModel = {
              title: '',
              dateY: '',
              dateM: '',
              dateD: '',
              image: '',
              abstract: '',
              cid: '',
              infoid: '',
              vip_AccountName: '',
              infoBrowseNum: ''
            }
            pageDataModel.title = rs.objectData.retData[i].info_Title;
            pageDataModel.abstract = rs.objectData.retData[i].info_Guide;
            pageDataModel.image = AppGlobal.domain + rs.objectData.retData[i].imagesViewModel.imgPath + rs.objectData.retData[i].imagesViewModel.imgName;
            pageDataModel.dateY = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate);
            pageDataModel.dateM = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate, 'month');
            pageDataModel.dateD = AppStaticConfig.DateFormat(rs.objectData.retData[i].info_CreateDate, 'date');
            pageDataModel.cid = rs.objectData.retData[i].info_CategoryId;
            pageDataModel.infoid = rs.objectData.retData[i].info_Id;
            pageDataModel.vip_AccountName = rs.objectData.retData[i].vip_AccountName;
            pageDataModel.infoBrowseNum = rs.objectData.retData[i].infoBrowseNum;
            tempArray.push(pageDataModel);
            console.log('5');
          }
          this.articlelist = tempArray;
          //console.log(tempArrayzy);
        }
      }
    });
  }
}
