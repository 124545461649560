import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-seb',
  templateUrl: './seb.component.html',
  styleUrls: ['./seb.component.scss']
})
export class SebComponent implements OnInit {
  seValue: any = '';//用户输入的编码
  constructor() { }

  ngOnInit() {
  }
//开始搜索
  /**
   * http://bht.gshsyy.com:99   //保和堂
   * http://sms.gshsyy.com      //专委会
   */
  goToSearch() {
    if (this.seValue != '' && this.seValue.length == 34) {
      location.href = 'http://bht.gshsyy.com:99/Global/SearchSource/New2QrCode?itemCode=' + this.seValue;
    }
    else {
      alert('请输入完整的34位编码')
    }
  }
}
